import React, { useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";

// Images
import copy_icon from "../../assets/images/copy_icon.svg";

// import components
import WalletDetails from './WalletDetails';
import TransactionHistory from './TransactionHistory';
import StakingHistory from './StakingHistory';

// import api
import { checkDeposit } from '../../api/walletAction';

let myInterval;
const Wallet = () => {

	useEffect(() => {
      myInterval = setInterval(() => {
          checkDeposit()
      }, 10000);
	}, []);

	useEffect(() => {
      return () => {
          clearInterval(myInterval);
      };
	}, []);

	return (
		<div className="innerpages_wrapper">
	        <div className="container">
	          <div className="inner_title_wrapper">
	            <h2 className="inner_title">Wallet</h2>
	          </div>
	          <div className="dash_wrapper">
	            <WalletDetails />
	            <div className="row">
	              <div className="col-xl-12">
	                <TransactionHistory />
	                <StakingHistory />
	              </div>
	            </div>
	          </div>
	        </div>
        </div>
	)
}

export default Wallet;