import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// Images
import copy_icon from "../assets/images/copy_icon.svg";

// import components
import Trade from '../components/Trade';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function TradePage(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper inner_title_wrapper_flex justify-content-between">
            <h2 className="inner_title">Start Staking</h2>
            {/* <div class="form-check form-switch cusform_form_switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"                
              />
              <label class="form-check-label" for="flexSwitchCheckChecked">
                Trade
              </label>
            </div> */}
          </div>
          <Trade />
        </div>
      </div>
      <Footer />
      <NavbarFixed />
    </div>
  );
}
