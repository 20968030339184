import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import lib
import { toFixed } from '../../lib/roundOf';
import { toastAlert } from '../../lib/toastAlert';

// import api
import { dailyReward } from '../../api/referralAction';



const Stake = () => {

    // hooks
    const dispatch = useDispatch();

    // redux-state
    const { userName, profile, profitStatus } = useSelector((state) => state.account);
    const [ walletDoc ] = useSelector((state) => state.wallet);
    const { startStake, endStake } = useSelector((state) => state.referralSettings);
    const { profitBal, currency : currencyDoc } = walletDoc;

    // state
    const [loader, setLoader] = useState(false)

    const handleSubmit = async () => {
        try {
            setLoader(true)
            let { status, message } = await dailyReward(dispatch);
            if(status === 'success') {
               toastAlert('success', message, 'dailyReward')
               setLoader(false)
            }
            else toastAlert('error', message, 'dailyReward')
            setLoader(false)  
        } catch (err) { }
    }

  	return (
  		<div className="col-lg-12 col-xl-4">
        <div className="dash_box">
          <div className="trade_user_detail">
            <img
              alt="Trade"
              className="img-fluid"
              src={profile ? profile : require("../../assets/images/copy_usericon.png")}
            />
            <h3>{userName}</h3>
          </div>
          <div className="deposit_asset_details">
            <div>
              <label>Profit </label>
              <label>{toFixed(profitBal, 2)} {currencyDoc?.currencySymbol}</label>
            </div>
            <div>
              <label>Following Members</label>
              <label>{startStake ? startStake : 0}/{endStake ? endStake : 0}</label>
            </div>
          </div>
        {
            profitStatus != 'completed' && (
            <div className="text-center mt-4 mb-4">
              <button 
                className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                type="button"
                onClick={handleSubmit}
                disabled={loader || profitStatus === 'completed'}
                >
                { !loader ? "Start Stake" : "Loading ..."}
              </button>
            </div>
           )
        }  
          <div
            role="alert"
            className="fade primary_warning_alert alert alert-secondary show"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                fill="#38DCA1"
              />
            </svg>
            <div>
              <p>
                You have to daily click start stake button to get your
                daily rewards.
              </p>
            </div>
          </div>
        </div>
      </div>
  	)
}

export default Stake;