import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

// import components
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NavbarFixed from "../components/Navbar-Fixed";
import Faq from "../components/Home/Faq";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  useEffect(() => {
    AOS.init();
  }, []);


  // hooks
  const navigate = useNavigate()

  return (

    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="container">
        <div className="page_header">
          <div className="row">
            <div className="col-xl-5">
              <h1 data-aos="fade-up" data-aos-duration="1000">Earn Passive Income with Your Crypto Holdings</h1>
              <h5 data-aos="fade-up" data-aos-duration="1000">
                Ready to take advantage of the exciting world of crypto staking?
                It's simple! Create a digital wallet, transfer your desired
                cryptocurrency, and stake your coins to start earning rewards.
              </h5>
              <button onClick={() => navigate('/trade')} className="primary_btn primary_btn_with_bg primary_btn_transform"  data-aos="fade-up" data-aos-duration="1000">
                Learn More Now!
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="section countdown_section">
          <div className="container">
            <div className="countdown_panel">
              <div className="countdown_single" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71"
                  height="70"
                  viewBox="0 0 71 70"
                  fill="none"
                >
                  <path
                    d="M0 30C0 13.4315 13.4315 0 30 0H41C57.5685 0 71 13.4315 71 30V40C71 56.5685 57.5685 70 41 70H30C13.4315 70 0 56.5685 0 40V30Z"
                    fill="#D2F6E9"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35 27.1326C32.7001 27.1326 30.8326 28.9422 30.8326 31.1709C30.8326 32.3874 31.433 33.9674 32.3745 35.0325C33.1025 35.8565 34.0285 36.369 35 36.369C35.9715 36.369 36.8975 35.8566 37.6255 35.0325C38.567 33.9674 39.1674 32.3874 39.1674 31.1709C39.1674 28.9422 37.2999 27.1326 35 27.1326Z"
                    fill="#21D796"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M26.5349 23.6621C24.7377 23.6621 23.2791 25.0755 23.2791 26.817C23.2791 27.7408 23.7323 28.9422 24.4472 29.7511C25.0242 30.4048 25.7652 30.7998 26.5349 30.7998C27.3045 30.7998 28.0456 30.4048 28.6225 29.7511C29.3375 28.9422 29.7907 27.7408 29.7907 26.817C29.7907 25.0755 28.3321 23.6621 26.5349 23.6621Z"
                    fill="#21D796"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M43.4651 23.6621C41.6679 23.6621 40.2093 25.0755 40.2093 26.817C40.2093 27.7408 40.6625 28.9422 41.3775 29.7511C41.9544 30.4048 42.6954 30.7998 43.4651 30.7998C44.2348 30.7998 44.9758 30.4048 45.5528 29.7511C46.2678 28.9422 46.721 27.7408 46.721 26.817C46.7209 25.0755 45.2623 23.6621 43.4651 23.6621Z"
                    fill="#21D796"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35 37.039C30.9367 37.039 27.6419 40.2318 27.6419 44.1692V45.4942C27.6419 46.5051 28.487 47.3241 29.5302 47.3241H40.4698C41.513 47.3241 42.3581 46.5051 42.3581 45.4942V44.1692C42.3581 40.2318 39.0633 37.039 35 37.039Z"
                    fill="#21D796"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M42.1537 39.4367H47.3721C48.2707 39.4367 49 38.7301 49 37.8593V36.9128C49 33.951 46.5217 31.5495 43.4651 31.5495C42.2891 31.5495 41.1991 31.9041 40.3031 32.5098C40.0296 33.6834 39.4318 34.929 38.6153 35.8527C38.4603 36.0281 38.2988 36.1909 38.1321 36.3424C39.773 36.9595 41.173 38.0486 42.1537 39.4367Z"
                    fill="#21D796"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.6969 32.5098C28.8009 31.9041 27.7109 31.5495 26.5349 31.5495C23.4783 31.5495 21 33.951 21 36.9128V37.8593C21 38.7301 21.7293 39.4368 22.6279 39.4368H27.8463C28.827 38.0486 30.2269 36.9595 31.8679 36.3424C31.7012 36.191 31.5397 36.0282 31.3847 35.8528C30.5682 34.929 29.9704 33.6834 29.6969 32.5098Z"
                    fill="#21D796"
                  />
                </svg>
                <div>
                  <h3>3000</h3>
                  <h4>All Participants</h4>
                </div>
              </div>
              <div className="countdown_single" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                >
                  <path
                    d="M0 30C0 13.4315 13.4315 0 30 0H40C56.5685 0 70 13.4315 70 30V40C70 56.5685 56.5685 70 40 70H30C13.4315 70 0 56.5685 0 40V30Z"
                    fill="#D2F6E9"
                  />
                  <path
                    d="M37.4887 30.7199V27.5031H44.8068V22.4272H24.493V27.5031H31.8317V30.7199C25.6835 31.0156 21.0679 32.2499 21.0679 33.7284C21.0679 35.2069 25.6784 36.4335 31.8317 36.7369V47.3541H37.4887V36.7369C43.6368 36.4438 48.2524 35.2095 48.2524 33.7284C48.2524 32.2473 43.6419 31.0156 37.4887 30.7199ZM34.6602 35.7752C28.0029 35.7752 22.6056 34.7467 22.6056 33.5073C22.6056 32.4401 26.5372 31.5453 31.8265 31.3062V34.9395C32.7368 34.9806 33.683 35.0038 34.655 35.0038C35.627 35.0038 36.5784 34.9806 37.4835 34.9395V31.3062C42.7728 31.5453 46.7044 32.4401 46.7044 33.5073C46.7147 34.7595 41.3174 35.7752 34.6602 35.7752Z"
                    fill="#21D796"
                  />
                </svg>
                <div>
                  <h3>12345</h3>
                  <h4>Total Earned USDT</h4>
                </div>
              </div>
              <div className="countdown_single" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                <svg
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 30C0 13.4315 13.4315 0 30 0H40C56.5685 0 70 13.4315 70 30V40C70 56.5685 56.5685 70 40 70H30C13.4315 70 0 56.5685 0 40V30Z"
                    fill="#D2F6E9"
                  />
                  <path
                    d="M32.2785 36.7846L33.1333 36.2418C34.2117 35.557 34.8555 34.3836 34.8555 33.103C34.8555 31.7772 33.7807 30.6986 32.4596 30.6986C31.1385 30.6986 30.0637 31.7772 30.0637 33.103V33.9022H31.6566V33.103C31.6566 32.6586 32.0168 32.2971 32.4596 32.2971C32.9024 32.2971 33.2627 32.6586 33.2627 33.103C33.2627 33.8325 32.896 34.5009 32.2816 34.891L31.4268 35.4338C30.5732 35.9759 30.0636 36.9047 30.0636 37.9185V38.7177H34.8554V37.1192H31.9185C32.0151 36.9886 32.1363 36.8748 32.2785 36.7846Z"
                    fill="#21D796"
                  />
                  <path
                    d="M40.5099 38.7158V35.9337H41.3129V34.3353H40.5099V32.0307H38.9171V34.3353H37.4344L37.9399 30.8277L36.3635 30.5989L35.5947 35.9337H38.9171V38.7158H40.5099Z"
                    fill="#21D796"
                  />
                  <path
                    d="M45.534 34.7082C45.534 40.3583 40.97 44.9384 35.3398 44.9384C29.6932 44.9384 25.1456 40.3748 25.1456 34.7082C25.1456 29.0581 29.7096 24.4779 35.3398 24.4779C38.1549 24.4779 40.7029 25.6235 42.5474 27.4751L41.3602 28.6665C41.4563 28.9238 41.468 28.9547 41.5641 29.2116L46.6877 29.5584L47.027 29.218L46.6813 24.0762C46.4254 23.9792 46.3946 23.968 46.1382 23.8716L44.9499 25.0635C42.4901 22.5949 39.0931 21.0684 35.3398 21.0679H35.3308C27.8418 21.0727 21.7338 27.2183 21.7476 34.7343C21.7614 42.2556 27.8418 48.3485 35.3398 48.3485C42.8463 48.3485 48.932 42.2413 48.932 34.7082H45.534Z"
                    fill="#21D796"
                  />
                </svg>

                <div>
                  <h3>24</h3>
                  <h4>joined in 24 hours</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-xl-6 d-none d-lg-block" data-aos="fade-up" data-aos-duration="1000">
                <img src={require("../assets/images/img_01.png")} alt="Image" className="img-fluid"/>
              </div>
              <div className="col-xl-6 col-lg-6 text-lg-start text-center" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="main_title">What is Staking</h2>
                <p className="main_para">
                  Ready to take advantage of the exciting world of crypto
                  staking? It's simple! Create a digital wallet, transfer your
                  desired cryptocurrency, and stake your coins to start earning
                  rewards. Whether you're a seasoned crypto enthusiast or a
                  newcomer, staking offers a valuable opportunity to engage with
                  blockchain networks in a meaningful way.
                </p>
                <button onClick={() => navigate('/trade')} className="primary_btn primary_btn_with_bg primary_btn_transform">
                  Stake Now
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="features_panel">
                  <h2 className="main_title text-center" data-aos="fade-up" data-aos-duration="1000">What you can get</h2>
                  <div className="row">
                    <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M14.4186 10.2326H6.97677C6.6067 10.2326 6.25179 10.0856 5.99011 9.8239C5.72843 9.56223 5.58142 9.20731 5.58142 8.83724C5.58142 8.46717 5.72843 8.11226 5.99011 7.85058C6.25179 7.5889 6.6067 7.44189 6.97677 7.44189H14.4186C14.7887 7.44189 15.1436 7.5889 15.4053 7.85058C15.667 8.11226 15.814 8.46717 15.814 8.83724C15.814 9.20731 15.667 9.56223 15.4053 9.8239C15.1436 10.0856 14.7887 10.2326 14.4186 10.2326Z"
                          fill="#21D796"
                        />
                        <path
                          d="M25.5814 15.8136H6.97677C6.6067 15.8136 6.25179 15.6666 5.99011 15.405C5.72843 15.1433 5.58142 14.7884 5.58142 14.4183C5.58142 14.0482 5.72843 13.6933 5.99011 13.4316C6.25179 13.17 6.6067 13.0229 6.97677 13.0229H25.5814C25.9515 13.0229 26.3064 13.17 26.5681 13.4316C26.8298 13.6933 26.9768 14.0482 26.9768 14.4183C26.9768 14.7884 26.8298 15.1433 26.5681 15.405C26.3064 15.6666 25.9515 15.8136 25.5814 15.8136Z"
                          fill="#21D796"
                        />
                        <path
                          d="M25.5814 21.3952H6.97677C6.6067 21.3952 6.25179 21.2482 5.99011 20.9865C5.72843 20.7248 5.58142 20.3699 5.58142 19.9998C5.58142 19.6298 5.72843 19.2749 5.99011 19.0132C6.25179 18.7515 6.6067 18.6045 6.97677 18.6045H25.5814C25.9515 18.6045 26.3064 18.7515 26.5681 19.0132C26.8298 19.2749 26.9768 19.6298 26.9768 19.9998C26.9768 20.3699 26.8298 20.7248 26.5681 20.9865C26.3064 21.2482 25.9515 21.3952 25.5814 21.3952Z"
                          fill="#21D796"
                        />
                        <path
                          d="M25.5814 26.9767H6.97677C6.6067 26.9767 6.25179 26.8297 5.99011 26.568C5.72843 26.3064 5.58142 25.9515 5.58142 25.5814C5.58142 25.2113 5.72843 24.8564 5.99011 24.5947C6.25179 24.333 6.6067 24.186 6.97677 24.186H25.5814C25.9515 24.186 26.3064 24.333 26.5681 24.5947C26.8298 24.8564 26.9768 25.2113 26.9768 25.5814C26.9768 25.9515 26.8298 26.3064 26.5681 26.568C26.3064 26.8297 25.9515 26.9767 25.5814 26.9767Z"
                          fill="#21D796"
                        />
                        <path
                          d="M25.5815 32.5583H18.1396C17.7695 32.5583 17.4146 32.4113 17.153 32.1496C16.8913 31.8879 16.7443 31.533 16.7443 31.1629C16.7443 30.7929 16.8913 30.4379 17.153 30.1763C17.4146 29.9146 17.7695 29.7676 18.1396 29.7676H25.5815C25.9515 29.7676 26.3065 29.9146 26.5681 30.1763C26.8298 30.4379 26.9768 30.7929 26.9768 31.1629C26.9768 31.533 26.8298 31.8879 26.5681 32.1496C26.3065 32.4113 25.9515 32.5583 25.5815 32.5583Z"
                          fill="#21D796"
                        />
                        <path
                          d="M34.8837 40H6.97674C5.12706 39.9978 3.35377 39.2621 2.04584 37.9542C0.737923 36.6462 0.00217631 34.8729 0 33.0233V3.25581C0.00104869 2.39264 0.344408 1.56512 0.954764 0.954764C1.56512 0.344408 2.39264 0.00104869 3.25581 0H29.3023C30.1655 0.00104869 30.993 0.344408 31.6034 0.954764C32.2137 1.56512 32.5571 2.39264 32.5581 3.25581V21.8605C32.5581 22.2305 32.4111 22.5854 32.1495 22.8471C31.8878 23.1088 31.5329 23.2558 31.1628 23.2558C30.7927 23.2558 30.4378 23.1088 30.1761 22.8471C29.9145 22.5854 29.7674 22.2305 29.7674 21.8605V3.25581C29.766 3.13291 29.7165 3.01544 29.6296 2.92853C29.5427 2.84162 29.4252 2.79215 29.3023 2.7907H3.25581C3.13291 2.79215 3.01544 2.84162 2.92853 2.92853C2.84162 3.01544 2.79215 3.13291 2.7907 3.25581V33.0233C2.79207 34.133 3.23353 35.197 4.01827 35.9817C4.80301 36.7665 5.86696 37.2079 6.97674 37.2093H34.8837C35.2538 37.2093 35.6087 37.3563 35.8704 37.618C36.1321 37.8797 36.2791 38.2346 36.2791 38.6047C36.2791 38.9747 36.1321 39.3296 35.8704 39.5913C35.6087 39.853 35.2538 40 34.8837 40Z"
                          fill="#212529"
                        />
                        <path
                          d="M34.8836 40C33.5271 39.9986 32.2266 39.4591 31.2674 38.5C30.3082 37.5408 29.7687 36.2402 29.7673 34.8837V27.4419C29.7673 27.0718 29.9143 26.7169 30.176 26.4552C30.4377 26.1935 30.7926 26.0465 31.1627 26.0465C31.5328 26.0465 31.8877 26.1935 32.1493 26.4552C32.411 26.7169 32.558 27.0718 32.558 27.4419V34.8837C32.558 35.5005 32.803 36.092 33.2392 36.5282C33.6753 36.9643 34.2668 37.2093 34.8836 37.2093C35.5004 37.2093 36.0919 36.9643 36.528 36.5282C36.9642 36.092 37.2092 35.5005 37.2092 34.8837V1.39535C37.2092 1.02528 37.3562 0.670367 37.6179 0.408688C37.8796 0.14701 38.2345 0 38.6045 0C38.9746 0 39.3295 0.14701 39.5912 0.408688C39.8529 0.670367 39.9999 1.02528 39.9999 1.39535V34.8837C39.9985 36.2402 39.459 37.5408 38.4998 38.5C37.5407 39.4591 36.2401 39.9986 34.8836 40Z"
                          fill="#212529"
                        />
                      </svg>
                      <h3>Staking guides</h3>
                      <p className="main_para">
                        Ready to take advantage of the exciting world of crypto
                        staking? It's simple! Create a digital wallet, transfer
                        your desired cryptocurrency, and stake your coins to
                        start earning rewards.{" "}
                      </p>
                    </div>
                    <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="40"
                        viewBox="0 0 42 40"
                        fill="none"
                      >
                        <path
                          d="M15.7149 12.856H11.429C10.639 12.856 10.0004 13.496 10.0004 14.2846C10.0004 15.0732 10.639 15.7132 11.429 15.7132H15.7149V18.5704H12.8577C11.2819 18.5704 10.0004 19.8519 10.0004 21.4277V24.2849C10.0004 25.8607 11.2819 27.1422 12.8577 27.1422H17.1435C17.9336 27.1422 18.5722 26.5022 18.5722 25.7136C18.5722 24.925 17.9336 24.2849 17.1435 24.2849H12.8577V21.4277H15.7149C17.2907 21.4277 18.5722 20.1462 18.5722 18.5704V15.7132C18.5722 14.1374 17.2907 12.856 15.7149 12.856Z"
                          fill="#212529"
                        />
                        <path
                          d="M28.5726 12.856C27.7825 12.856 27.1439 13.496 27.1439 14.2846V18.5704H25.7153C24.9267 18.5704 24.2867 17.929 24.2867 17.1418V14.2846C24.2867 13.496 23.6481 12.856 22.8581 12.856C22.068 12.856 21.4294 13.496 21.4294 14.2846V17.1418C21.4294 19.5048 23.3524 21.4277 25.7153 21.4277H27.1439V25.7136C27.1439 26.5022 27.7825 27.1422 28.5726 27.1422C29.3626 27.1422 30.0012 26.5022 30.0012 25.7136V14.2846C30.0012 13.496 29.3626 12.856 28.5726 12.856Z"
                          fill="#212529"
                        />
                        <path
                          d="M41.3558 28.1196C41.1058 27.3724 40.3043 26.9652 39.5486 27.2167L38.4871 27.571C39.4643 25.178 40 22.6008 40 20.0007C40 8.97175 31.0283 0 19.9993 0C8.97032 0 0 8.97032 0 19.9993C0 31.0283 8.97175 40 20.0007 40C20.7907 40 21.4293 39.36 21.4293 38.5714C21.4293 37.7828 20.7907 37.1428 20.0007 37.1428C10.5475 37.1428 2.85724 29.4525 2.85724 19.9993C2.85724 10.5461 10.5475 2.85582 20.0007 2.85582C29.4539 2.85582 37.1442 10.5461 37.1442 19.9993C37.1442 22.1322 36.7327 24.2509 35.9641 26.2267L35.6413 25.2609C35.3913 24.5137 34.5912 24.1051 33.8341 24.358C33.0869 24.608 32.6812 25.4166 32.9312 26.1652L34.3598 30.4511C34.5598 31.0497 35.1155 31.4283 35.7141 31.4283C35.8627 31.4283 36.0156 31.404 36.1656 31.354L40.4514 29.9254C41.1986 29.6753 41.6043 28.8667 41.3543 28.1181L41.3558 28.1196Z"
                          fill="#212529"
                        />
                        <path
                          d="M23.4245 36.9588C23.2112 37.2967 23.0828 37.7591 23.0828 38.2214C23.0828 38.7016 23.2112 39.1462 23.4245 39.4841C23.6472 39.8219 23.9547 39.9998 24.261 39.9998C24.5673 39.9998 24.8736 39.8219 25.0975 39.4841C25.3095 39.1462 25.4391 38.7016 25.4391 38.2214C25.4391 37.7591 25.3095 37.2967 25.0975 36.9588C24.6615 36.3008 23.8604 36.3008 23.4245 36.9588Z"
                          fill="#21D796"
                        />
                        <path
                          d="M29.6393 35.6301C29.2328 35.3593 28.6143 35.2298 28.0311 35.3122C27.925 35.324 27.819 35.3475 27.713 35.3828C27.6052 35.4064 27.5009 35.4417 27.3931 35.4888L27.1298 35.6301C26.9708 35.7479 26.847 35.8774 26.7587 36.0188C26.6703 36.1601 26.6173 36.3132 26.6173 36.4663C26.6173 36.6194 26.6703 36.7724 26.7587 36.9138C26.847 37.0669 26.9708 37.1846 27.1298 37.3024C27.2182 37.3495 27.3065 37.3966 27.3931 37.4437C27.5009 37.4908 27.6052 37.5261 27.713 37.5497C27.819 37.585 27.925 37.6086 28.0311 37.6203C28.1548 37.6321 28.2608 37.6439 28.3845 37.6439C28.844 37.6439 29.3035 37.5144 29.6393 37.3024C29.9574 37.0786 30.1517 36.7724 30.1517 36.4663C30.1517 36.3132 30.1146 36.1601 30.0104 36.0188C29.922 35.8774 29.7983 35.7479 29.6393 35.6301Z"
                          fill="#21D796"
                        />
                        <path
                          d="M33.1737 33.2601C32.5021 32.8215 31.3358 32.8215 30.6642 33.2601C30.3444 33.4854 30.1517 33.7936 30.1517 34.1019C30.1517 34.4101 30.3444 34.7184 30.6642 34.9436C30.9982 35.157 31.4595 35.2874 31.919 35.2874C32.3784 35.2874 32.8379 35.157 33.1737 34.9436C33.4918 34.7184 33.6862 34.4101 33.6862 34.1019C33.6862 33.7936 33.4918 33.4854 33.1737 33.2601Z"
                          fill="#21D796"
                        />
                      </svg>
                      <h3>24/7 live support</h3>
                      <p className="main_para">
                        Our 24/7 Live Support service ensures that you have a
                        reliable partner ready to address your queries,
                        concerns, and technical challenges, no matter the time
                        zone.
                      </p>
                    </div>
                    <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M8.83724 14.4189C8.07445 14.4189 7.44189 13.7863 7.44189 13.0235V9.30257C7.44189 8.53978 8.07445 7.90723 8.83724 7.90723C9.60003 7.90723 10.2326 8.53978 10.2326 9.30257V13.0235C10.2326 13.7863 9.60003 14.4189 8.83724 14.4189Z"
                          fill="#21D796"
                        />
                        <path
                          d="M16.2791 14.4189C15.5163 14.4189 14.8838 13.7863 14.8838 13.0235V9.30257C14.8838 8.53978 15.5163 7.90723 16.2791 7.90723C17.0419 7.90723 17.6745 8.53978 17.6745 9.30257V13.0235C17.6745 13.7863 17.0419 14.4189 16.2791 14.4189Z"
                          fill="#21D796"
                        />
                        <path
                          d="M8.83724 32.558C8.07445 32.558 7.44189 31.9255 7.44189 31.1627V27.4417C7.44189 26.6789 8.07445 26.0464 8.83724 26.0464C9.60003 26.0464 10.2326 26.6789 10.2326 27.4417V31.1627C10.2326 31.9255 9.60003 32.558 8.83724 32.558Z"
                          fill="#21D796"
                        />
                        <path
                          d="M16.2791 32.558C15.5163 32.558 14.8838 31.9255 14.8838 31.1627V27.4417C14.8838 26.6789 15.5163 26.0464 16.2791 26.0464C17.0419 26.0464 17.6745 26.6789 17.6745 27.4417V31.1627C17.6745 31.9255 17.0419 32.558 16.2791 32.558Z"
                          fill="#21D796"
                        />
                        <path
                          d="M31.1628 12.5583H23.721C22.9582 12.5583 22.3256 11.9257 22.3256 11.1629C22.3256 10.4001 22.9582 9.76758 23.721 9.76758H31.1628C31.9256 9.76758 32.5582 10.4001 32.5582 11.1629C32.5582 11.9257 31.9256 12.5583 31.1628 12.5583Z"
                          fill="#21D796"
                        />
                        <path
                          d="M20 40H14.4186C4.31628 40 0 35.6837 0 25.5814V14.4186C0 4.31628 4.31628 0 14.4186 0H25.5814C35.6837 0 40 4.31628 40 14.4186V20C40 20.7628 39.3674 21.3953 38.6046 21.3953C37.8419 21.3953 37.2093 20.7628 37.2093 20V14.4186C37.2093 5.84186 34.1581 2.7907 25.5814 2.7907H14.4186C5.84186 2.7907 2.7907 5.84186 2.7907 14.4186V25.5814C2.7907 34.1581 5.84186 37.2093 14.4186 37.2093H20C20.7628 37.2093 21.3953 37.8419 21.3953 38.6046C21.3953 39.3674 20.7628 40 20 40Z"
                          fill="#212529"
                        />
                        <path
                          d="M38.6046 21.3952H1.39535C0.632558 21.3952 0 20.7626 0 19.9998C0 19.2371 0.632558 18.6045 1.39535 18.6045H38.6046C39.3674 18.6045 40 19.2371 40 19.9998C40 20.7626 39.3674 21.3952 38.6046 21.3952Z"
                          fill="#212529"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M38.6035 24.6514C39.3741 24.6514 39.9989 25.2761 39.9989 26.0467V29.7608C40.0072 30.2432 39.7648 30.7167 39.3215 30.9827C39.026 31.16 38.6915 31.2151 38.3763 31.163H34.8826C34.112 31.163 33.4872 30.5382 33.4872 29.7676C33.4872 28.997 34.112 28.3723 34.8826 28.3723H34.9451C34.279 28.053 33.4846 27.8328 32.557 27.8328C30.8206 27.8328 29.3224 28.7613 28.522 30.1622C28.1397 30.8313 27.2872 31.0638 26.6182 30.6815C25.949 30.2992 25.7166 29.4467 26.099 28.7777C27.3821 26.5319 29.791 25.0421 32.557 25.0421C34.5056 25.0421 36.0595 25.6938 37.2082 26.4586V26.0467C37.2082 25.2761 37.8329 24.6514 38.6035 24.6514Z"
                          fill="#212529"
                        />
                        <path
                          d="M27.1803 33.749C26.411 33.749 25.7871 34.3715 25.7849 35.1403V38.6048C25.7849 39.3754 26.4097 40.0002 27.1803 40.0002C27.9509 40.0002 28.5756 39.3754 28.5756 38.6048V38.5836C29.5931 39.2945 30.9377 39.9072 32.557 39.9072C35.3051 39.9072 37.7109 38.4169 38.9933 36.1955C39.3786 35.5282 39.1499 34.6748 38.4826 34.2895C37.8151 33.9042 36.9618 34.1328 36.5765 34.8002C35.7752 36.1881 34.274 37.1165 32.557 37.1165C31.8227 37.1165 31.1496 36.8863 30.5538 36.5397H30.9012C31.6718 36.5397 32.2965 35.915 32.2965 35.1444C32.2965 34.3738 31.6718 33.749 30.9012 33.749H27.1803Z"
                          fill="#212529"
                        />
                      </svg>
                      <h3>Regular updates</h3>
                      <p className="main_para">
                        Our regular crypto staking updates provide you with the
                        latest insights, trends, and developments in the world
                        of staking and blockchain technology.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 d-none d-lg-block" data-aos="fade-up" data-aos-duration="1000">
                <img src={require("../assets/images/img_02.png")} alt="Image" className="img-fluid" />
              </div>
              <div className="col-xl-6 col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="main_title">How Staking Works</h2>
                <div className="staking_works">
                  <div>
                    <h2>You Stake Crypto</h2>
                    <p className="main_para">
                      Stake your crypto in the Atomic Wallet app
                    </p>
                  </div>
                  <div>
                    <h2>Validator creates blocks</h2>
                    <p className="main_para">
                      Receive regular staking rewards while supporting the
                      blockchain
                    </p>
                  </div>
                  <div>
                    <h2>Receive Profit</h2>
                    <p className="main_para">
                      Rewards will be transferred to your wallet automatically.
                      For some coins, you will need to claim rewards manually.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faq />
      </div>
      <Footer />
      <NavbarFixed />
    </div>
  );
}
