import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// import lib
import { findPerc } from '../../lib/calculation'

const UserLevel = () => {

	// redux-state
	const { userCode, refCount, refLevel, refLevelPoints, referralCode } = useSelector((state) => state.account)
	const [ walletDoc ] = useSelector((state) => state.wallet)
	const levelList = useSelector((state) => state.referralLevel)
	const { depositBal, profitBal, comissionBal, currency : currencyDoc } = walletDoc;

	// state
	const [nextLevAmount, setNextLevAmount] = useState(0);

  let percentage = findPerc(refLevelPoints, nextLevAmount)
  
	useEffect(() => {
	 	let nextLev = refLevel + 1;
	 	if(levelList && levelList.length > 0) {
	 			let checkLev = levelList.find((el) => el.reachAmount > refLevelPoints);
	 			if(checkLev) setNextLevAmount(checkLev.reachAmount)
		}
	}, [refLevel, levelList])

	return (
  		<div className="row level_top_row">
        <div className="col-lg-3">
          <div className="dash_box dash_box_left_flex dash_box_left">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.02083 21.75C3.82175 21.7492 4.58963 21.4306 5.15597 20.8643C5.7223 20.298 6.04083 19.5301 6.04167 18.7292V17.5208C6.04167 16.7197 5.7234 15.9513 5.15688 15.3848C4.59037 14.8183 3.82201 14.5 3.02083 14.5C2.21966 14.5 1.4513 14.8183 0.884782 15.3848C0.318265 15.9513 0 16.7197 0 17.5208L0 18.7292C0.00083166 19.5301 0.319364 20.298 0.8857 20.8643C1.45204 21.4306 2.21991 21.7492 3.02083 21.75ZM2.41667 17.5208C2.41667 17.3606 2.48032 17.2069 2.59362 17.0936C2.70693 16.9803 2.8606 16.9167 3.02083 16.9167C3.18107 16.9167 3.33474 16.9803 3.44804 17.0936C3.56135 17.2069 3.625 17.3606 3.625 17.5208V18.7292C3.625 18.8894 3.56135 19.0431 3.44804 19.1564C3.33474 19.2697 3.18107 19.3333 3.02083 19.3333C2.8606 19.3333 2.70693 19.2697 2.59362 19.1564C2.48032 19.0431 2.41667 18.8894 2.41667 18.7292V17.5208Z"
                fill="#626262"
              />
              <path
                d="M10.6738 21.7498C11.4747 21.749 12.2426 21.4305 12.809 20.8641C13.3753 20.2978 13.6938 19.5299 13.6947 18.729V12.6873C13.6947 11.8862 13.3764 11.1178 12.8099 10.5513C12.2434 9.98477 11.475 9.6665 10.6738 9.6665C9.87264 9.6665 9.10428 9.98477 8.53777 10.5513C7.97125 11.1178 7.65298 11.8862 7.65298 12.6873V18.729C7.65382 19.5299 7.97235 20.2978 8.53868 20.8641C9.10502 21.4305 9.8729 21.749 10.6738 21.7498ZM10.0697 12.6873C10.0697 12.5271 10.1333 12.3734 10.2466 12.2601C10.3599 12.1468 10.5136 12.0832 10.6738 12.0832C10.8341 12.0832 10.9877 12.1468 11.101 12.2601C11.2143 12.3734 11.278 12.5271 11.278 12.6873V18.729C11.278 18.8892 11.2143 19.0429 11.101 19.1562C10.9877 19.2695 10.8341 19.3332 10.6738 19.3332C10.5136 19.3332 10.3599 19.2695 10.2466 19.1562C10.1333 19.0429 10.0697 18.8892 10.0697 18.729V12.6873Z"
                fill="#626262"
              />
              <path
                d="M18.3262 21.7502C19.1271 21.7493 19.895 21.4308 20.4613 20.8645C21.0277 20.2981 21.3462 19.5302 21.347 18.7293V7.85433C21.347 7.05315 21.0288 6.28479 20.4622 5.71828C19.8957 5.15176 19.1274 4.8335 18.3262 4.8335C17.525 4.8335 16.7567 5.15176 16.1901 5.71828C15.6236 6.28479 15.3054 7.05315 15.3054 7.85433V18.7293C15.3062 19.5302 15.6247 20.2981 16.1911 20.8645C16.7574 21.4308 17.5253 21.7493 18.3262 21.7502ZM17.722 7.85433C17.722 7.69409 17.7857 7.54042 17.899 7.42712C18.0123 7.31382 18.166 7.25016 18.3262 7.25016C18.4864 7.25016 18.6401 7.31382 18.7534 7.42712C18.8667 7.54042 18.9304 7.69409 18.9304 7.85433V18.7293C18.9304 18.8896 18.8667 19.0432 18.7534 19.1565C18.6401 19.2698 18.4864 19.3335 18.3262 19.3335C18.166 19.3335 18.0123 19.2698 17.899 19.1565C17.7857 19.0432 17.722 18.8896 17.722 18.7293V7.85433Z"
                fill="#626262"
              />
              <path
                d="M25.9792 0C25.1783 0.00083166 24.4104 0.319364 23.844 0.8857C23.2777 1.45204 22.9592 2.21991 22.9583 3.02083V18.7292C22.9583 19.5303 23.2766 20.2987 23.8431 20.8652C24.4096 21.4317 25.178 21.75 25.9792 21.75C26.7804 21.75 27.5487 21.4317 28.1152 20.8652C28.6817 20.2987 29 19.5303 29 18.7292V3.02083C28.9992 2.21991 28.6806 1.45204 28.1143 0.8857C27.548 0.319364 26.7801 0.00083166 25.9792 0ZM26.5833 18.7292C26.5833 18.8894 26.5197 19.0431 26.4064 19.1564C26.2931 19.2697 26.1394 19.3333 25.9792 19.3333C25.8189 19.3333 25.6653 19.2697 25.552 19.1564C25.4387 19.0431 25.375 18.8894 25.375 18.7292V3.02083C25.375 2.8606 25.4387 2.70693 25.552 2.59362C25.6653 2.48032 25.8189 2.41667 25.9792 2.41667C26.1394 2.41667 26.2931 2.48032 26.4064 2.59362C26.5197 2.70693 26.5833 2.8606 26.5833 3.02083V18.7292Z"
                fill="#626262"
              />
              <path
                d="M27.7917 26.5832H16.9167C16.5962 26.5832 16.2889 26.4559 16.0623 26.2293C15.8356 26.0027 15.7083 25.6953 15.7083 25.3748C15.7083 25.0544 15.8356 24.747 16.0623 24.5204C16.2889 24.2938 16.5962 24.1665 16.9167 24.1665H27.7917C28.1121 24.1665 28.4195 24.2938 28.6461 24.5204C28.8727 24.747 29 25.0544 29 25.3748C29 25.6953 28.8727 26.0027 28.6461 26.2293C28.4195 26.4559 28.1121 26.5832 27.7917 26.5832Z"
                fill="#02D287"
              />
              <path
                d="M12.0833 26.5832H1.20833C0.887863 26.5832 0.580519 26.4559 0.353913 26.2293C0.127306 26.0027 0 25.6953 0 25.3748C0 25.0544 0.127306 24.747 0.353913 24.5204C0.580519 24.2938 0.887863 24.1665 1.20833 24.1665H12.0833C12.4038 24.1665 12.7111 24.2938 12.9378 24.5204C13.1644 24.747 13.2917 25.0544 13.2917 25.3748C13.2917 25.6953 13.1644 26.0027 12.9378 26.2293C12.7111 26.4559 12.4038 26.5832 12.0833 26.5832Z"
                fill="#02D287"
              />
              <path
                d="M14.5 29C13.783 29 13.0822 28.7874 12.4861 28.3891C11.8899 27.9908 11.4253 27.4246 11.1509 26.7622C10.8766 26.0998 10.8048 25.371 10.9447 24.6678C11.0845 23.9646 11.4298 23.3187 11.9367 22.8117C12.4437 22.3048 13.0896 21.9595 13.7928 21.8197C14.496 21.6798 15.2248 21.7516 15.8872 22.0259C16.5496 22.3003 17.1158 22.7649 17.5141 23.3611C17.9124 23.9572 18.125 24.658 18.125 25.375C18.1239 26.3361 17.7417 27.2575 17.0621 27.9371C16.3825 28.6167 15.4611 28.9989 14.5 29ZM14.5 24.1667C14.261 24.1667 14.0274 24.2375 13.8287 24.3703C13.63 24.5031 13.4751 24.6918 13.3836 24.9126C13.2922 25.1334 13.2683 25.3763 13.3149 25.6107C13.3615 25.8451 13.4766 26.0604 13.6456 26.2294C13.8146 26.3984 14.0299 26.5135 14.2643 26.5601C14.4987 26.6067 14.7416 26.5828 14.9624 26.4914C15.1832 26.3999 15.3719 26.245 15.5047 26.0463C15.6375 25.8476 15.7083 25.614 15.7083 25.375C15.708 25.0546 15.5806 24.7474 15.3541 24.5209C15.1276 24.2944 14.8204 24.167 14.5 24.1667Z"
                fill="#02D287"
              />
            </svg>
            <div>
              <h3>{refLevel}</h3>
              <h4>Level</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="dash_box dash_box_right">
            <h5>Level {refLevel + 1}</h5>
            <div className="custom_progress">
              <div class="progress">
                <div
                  class={`progress-bar w-${percentage}`}
                  role="progressbar"
                  aria-label="Basic example"
                  aria-valuenow="10"
                  aria-valuemin="10"
                  aria-valuemax="100"
                  style={{ 'width': `${percentage}%` }}
                ></div>
              </div>
              <div className="progress_note">
                <label>Progress towards level amount achieve</label>
                <label>{refLevelPoints} {currencyDoc?.currencySymbol} / {nextLevAmount} {currencyDoc?.currencySymbol}</label>
              </div>
              <div className="progress_value">{percentage}%</div>
            </div>
          </div>
        </div>
      </div>
	)
}

export default UserLevel;