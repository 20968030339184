import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";

// api
import { createUser } from '../../api/users';

// lib
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
	"email" : "",
	"userName" : "",
	"password" : "",
	"confirmPassword" : "",
	"referralCode" : "",
	"isTerms" : "",
	"otpStatus" : false,
	"otp" : ""
}

const RegisterForm = () => {
    
    const navigate = useNavigate()
    const location = useLocation();
  	const queryParams = new URLSearchParams(location.search);

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [errors, setErrors] = useState({});
    const { email, userName, password, confirmPassword, referralCode, isTerms, otpStatus, otp } = formValue;
	
	const handleChange = (e) => {
		const { name, value, checked } = e.target;
		setFormValue({ ...formValue, ... { [name] : name != 'isTerms' ? value : checked } })
	}

	const handleSubmit = async (e) => {
		try {

			const { status, message, error } = await createUser(formValue);
			if (status == 'success') {
	            setFormValue(initialFormValue)
	            toastAlert('success', message, 'signup', 'TOP_CENTER');
	            navigate('/login')
	        } else if (status === 'OTP') {
	        	setFormValue({ ...formValue, ... { 'otpStatus' : true } })
	        	setErrors({});
	        	toastAlert('success', message, 'signup', 'TOP_CENTER');
	        } else {
	            if (error) {
	                setErrors(error);
	                return false;
	            }
	            toastAlert('error', message, 'signup', 'TOP_CENTER');
	        }
		} catch (err) { }
	}

	useEffect(() => {
		if(queryParams.get('ref')) {
			setFormValue({ ...formValue, ... { 'referralCode' : queryParams.get('ref') } })
		}
	}, [queryParams.get('ref')])
	return (
		<div className="container">            
	        <div className="row">
	          <div className="col-xl-6 m-auto">
	            <div className="dash_box mb-0">
	              <div className="inner_title_wrapper mt-2">
	                <h2 className="inner_title">Register</h2>
	              </div>
	              <form className="auth_form">
	                <div class="mb-3">
	                  <label for="exampleFormControlInput1" class="form-label">
	                    Email Address
	                  </label>
	                  <input
	                    type="email"
	                    class="form-control"
	                    id="exampleFormControlInput1"
	                    placeholder="Enter Email Address"
	                    name='email'
	                    onChange={handleChange}
	                    value={email}
	                  />
	                  <span className="text-danger">{errors?.email}</span>
	                </div>
	                <div class="mb-3">
	                  <label for="exampleFormControlInput1" class="form-label">
	                    User Name
	                  </label>
	                  <input
	                    type="text"
	                    class="form-control"
	                    id="exampleFormControlInput1"
	                    placeholder="Enter User Name"
	                    name='userName'
	                    onChange={handleChange}
	                    value={userName}
	                  />
	                  <span className="text-danger">{errors?.userName}</span>
	                </div>
	                <div class="mb-3">
	                  <label for="inputPassword" class="form-label">
	                    Password
	                  </label>
	                  <input
	                    type="password"
	                    class="form-control"
	                    id="inputPassword"
	                    placeholder="Enter Password"
	                    name='password'
	                    onChange={handleChange}
	                    value={password}
	                  />
	                  <span className="text-danger">{errors?.password}</span>
	                </div>
	                <div class="mb-3">
	                  <label for="inputPassword" class="form-label">
	                    Confirm Password
	                  </label>
	                  <input
	                    type="password"
	                    class="form-control"
	                    id="inputPassword"
	                    placeholder="Enter Confirm Password"
	                    name='confirmPassword'
	                    onChange={handleChange}
	                    value={confirmPassword}
	                  />
	                  <span className="text-danger">{errors?.confirmPassword}</span>
	                </div>
	                <div class="mb-3">
	                  <label for="inputPassword" class="form-label">
	                    Referral (Optional)
	                  </label>
	                  <input
	                    type="text"
	                    class="form-control"
	                    id="referral"
	                    placeholder="Enter Referral ID"
	                    name='referralCode'
	                    onChange={handleChange}
	                    value={referralCode}
	                  />
	                  <span className="text-danger">{errors?.referralCode}</span>
	                </div>
	            {
	            	(otpStatus === true) && (
	            		<div class="mb-3">
		                  <label for="inputPassword" class="form-label">
		                    OTP
		                  </label>
		                  <input
		                    type="text"
		                    class="form-control"
		                    id="otp"
		                    placeholder="Enter OTP"
		                    name='otp'
		                    onChange={handleChange}
		                    value={otp}
		                  />
		                  <span className="text-danger">{errors?.otp}</span>
		                </div>
	            	)
	            }
	                <div class="form-check">
	                  <input
	                    class="form-check-input"
	                    type="checkbox"
	                    id="inlineCheckbox1"
	                    name="isTerms"
	                    onChange={handleChange}
	                    checked={isTerms}
	                  />
	                  <label class="form-check-label" for="inlineCheckbox1">
	                    I Understand <Link to="/terms">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>
	                  </label>
	                </div>
	                <span className="text-danger">{errors?.isTerms}</span><br />
	                <div className="text-center mt-4 mb-4">
	                    <button 
	                      type='button' 
	                      class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
	                      onClick={handleSubmit}
	                    >
	                    Register
	                  </button>
	                </div>                    
	                <p class="form_note text-center mt-3">
	                  Already Registerd <Link to="/login">Sign In</Link>
	                </p>
	              </form>
	            </div>
	          </div>
	        </div>
	    </div>
	)
}

export default RegisterForm;