import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NavbarFixed from "../components/Navbar-Fixed.js";

// Images
import { Link } from "react-router-dom";

// import components
import Deposit from '../components/Deposit';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function DepositPage(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper inner_title_wrapper_flex">
            <Link to="/wallet">
              <img
                alt="Nav"
                className="img-fluid"
                src={require("../assets/images/nav_arrow.png")}
              />
            </Link>
            <h2 className="inner_title">Deposit</h2>
          </div>
          <Deposit />
        </div>
      </div>
      <Footer />
      <NavbarFixed />
    </div>
  );
}
