// import config
import axios, { handleResp } from '../config/axios'

// import constant
import {
    SET_USER_WALLET_LIST,
    // SET_USER_FIRST_CURRENCY,
    // SET_USER_SECOND_CURRENCY,
    // UPDATE_USER_WALLET_STAKE
} from '../constant';

export const getAssetData = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getAssetsDetails`,
        });
        dispatch(userWalletList(respData.data.result))
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const userWalletList = (data) => {
    return {
        type: SET_USER_WALLET_LIST,
        data
    }
}

export const coinRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/coinWithdraw`,
            'data': data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const coinWithdrawReq = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/coinWithdraw`,
            'data': data
        });

        getAssetData(dispatch)
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        }
    }
}

export const getTrnxHistory = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/transaction-history`,
            params
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const checkDeposit = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/check-deposit`,
        });
        return true
    }
    catch (err) {
        handleResp(err, 'error')
        return false
    }
}

export const walletTransfer = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/walletTransfer`,
            'data': data
        });

        getAssetData(dispatch)
        
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors : err.response.data.errors
        }
    }
}

// export const updateWallet = async (dispatch, data, callFrom) => {
//     if (callFrom == 'stake') {
//         dispatch({
//             type: UPDATE_USER_WALLET_STAKE,
//             data
//         })
//     }
//     return false

// }

// export const withdrawRequestFiat = async (data, dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/fiatWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);

//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status: "OTP",
//                 loading: false,
//                 message: respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const cashFreeWithdrawReq = async (data,dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/cashFreeWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);
        
//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status: "OTP",
//                 loading: false,
//                 message: respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const payatMeWithdrawReq = async (data,dispatch) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/payatMeWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);
        
//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status: "OTP",
//                 loading: false,
//                 message: respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const fiatRequestVerify = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'patch',
//             'url': `/api/fiatWithdraw`,
//             'data': data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//         }
//     }
// }

// // WITHDRAW REQUSET FUNCTION and by ADMIN APPROVE
// export const withdrawRequestCoin = async (data,dispatch) => { 
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/coinWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);

//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status : respData.data.success,
//                 message : respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// //Auto withdraw
// export const autoWithdrawRequestCoin = async (data,dispatch) => { 
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/coinAutoWithdraw`,
//             'data': data
//         });

//         getAssetData(dispatch);

//         if(respData && respData.data && respData.data.success == 'OTP'){
//             return {
//                 status : respData.data.success,
//                 message : respData.data.message
//             }
//         }

//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const fiatDepositRequest = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/fiatDeposit`,
//             'data': data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const fundTransfer = async (data) => {
//     try {
//         let respData = await axios({
//             'method': 'post',
//             'url': `/api/fundTransfer`,
//             'data': data
//         });
//         return {
//             status: "success",
//             loading: false,
//             message: respData.data.message,
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//             message: err.response.data.message,
//             error: err.response.data.errors
//         }
//     }
// }

// export const getAssetByCurrency = async (currencyId) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/getAsset/` + currencyId
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }

// export const setUserFirstCurrency = (assetData, dispatch) => {
//     dispatch({
//         type: SET_USER_FIRST_CURRENCY,
//         data: assetData
//     })
// }

// export const setUserSecondCurrency = (assetData, dispatch) => {
//     dispatch({
//         type: SET_USER_SECOND_CURRENCY,
//         data: assetData
//     })
// }

// export const getTrnxHistory = async (params, query) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/history/transaction/` + params,
//             'params': query
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }