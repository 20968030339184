import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';

// import bimages
import copy_icon from "../../assets/images/copy_icon.svg";

// import lib
import { toastAlert } from '../../lib/toastAlert';
import { toFixed } from '../../lib/roundOf';

// import api
import { coinWithdrawReq } from '../../api/walletAction';

const initialFormValue = { "receiverAddress" : "", "amount" : "" }
const Withdraw = () => {

    // hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { walletType } = useParams();

    // redux-state
    const walletData = useSelector((state) => state.wallet);

    // state
    const [formValue, setFormValue] = useState(initialFormValue)
    const [walletDoc, setWalletDoc] = useState({});
    const [currencyDoc, setCurrencyDoc] = useState({});
    const [errors, setErrors] = useState({});
    const [estimateBal, setEstimateBal] = useState(0);
    
    const { receiverAddress, amount } = formValue;

    const handleChange = (e) => {
       const { name, value } = e.target;
       setFormValue({ ...formValue, ... { [name] : value } })
    }

    const handleSubmit = async () => {
        try { 
            const { status, message, errors } = await coinWithdrawReq({ currencyId : currencyDoc._id, receiverAddress, amount, walletType }, dispatch)
            if(status === "success") {
                toastAlert('success', message)
                setErrors({})
                setFormValue(initialFormValue)
            } else {  
              setErrors(errors)
            }
        } catch (err) { }
    } 

    useEffect(() => {
       if(currencyDoc && currencyDoc.withdrawFee > 0) {
            let estimateAmount = parseFloat(amount) + parseFloat(currencyDoc.withdrawFee)
            setEstimateBal(estimateAmount)
       }
    }, [amount])

    useEffect(() => {
       if(walletData && walletData.length > 0) {
            let walletDoc = walletData[0];
            walletDoc && setWalletDoc(walletDoc)
            setCurrencyDoc(walletDoc.currency)
       }
    }, [walletData])

    useEffect(() => {
       if(!['profitBal', 'comissionBal'].includes(walletType)) navigate('/wallet')
    }, [walletType])

  	return (
  		<div className="dash_wrapper">
        <div className="row">
          <div className="col-lg-12">
            <div className="dash_box asset_dashbox_flex asset_dashbox_flex_line">
              <div className="asset_dashbox_flex_left">
                <h3 className="asset_deposit_innertitle">
                  Withdraw Terms
                </h3>
                <p>The Minimum Withdrawal amount is: {currencyDoc?.minimumWithdraw } {currencyDoc?.currencySymbol} ({currencyDoc?.tokenType?.toUpperCase()})</p>
                <ul className="deposit_notes_list">
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                  <li>
                    The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed.
                  </li>
                </ul>
              </div>
              <div className="asset_dashbox_flex_right">
                <h3 className="asset_deposit_innertitle">{currencyDoc?.currencySymbol} Withdraw</h3>
                <form className="assets_form">
                  <div className="mb-3">
                    <label for="withdrawaddress" className="form-label">
                      Receiver Address
                    </label>
                    <input
                      type="text"
                      className="form-control primary_asset_inp"
                      id="receiverAddress"
                      placeholder="Please enter withdrawal address here"
                      name="receiverAddress"
                      value={receiverAddress}
                      onChange={handleChange}
                    />
                    <span className='text-danger'>{errors?.receiverAddress}</span>
                  </div>
                  <div className="mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <label for="withdrawamount" className="form-label">
                        Withdrawal amount
                      </label>
                      {/*<label className="max_label">Max</label>*/}
                    </div>
                    <div className="asset_deposit_grp">
                      <input
                        type="text"
                        className="form-control primary_asset_inp"
                        id="amount"
                        name="amount"
                        value={amount}
                        onChange={handleChange}
                        placeholder="Enter Amount"
                      />
                      <span className='text-danger'>{errors?.amount}</span>
                      <span className="withdrawal_type">{currencyDoc?.currencySymbol}</span>
                    </div>
                  </div>
                  <div className="deposit_asset_details">
                    {
                       walletDoc && walletDoc[walletType] ? (
                        <div>
                          <label>Available</label>
                          <label>{toFixed(walletDoc[walletType], 2)} {currencyDoc?.currencySymbol}</label>
                        </div>
                       ) : (
                        <div>
                          <label>Available</label>
                          <label>{'0.00'} {currencyDoc?.currencySymbol}</label>
                        </div>
                       )
                        
                    }
                    {
                      currencyDoc && currencyDoc.withdrawFee && 
                      <div>
                        <label>Fees</label>
                        <label>{currencyDoc?.withdrawFee} {currencyDoc?.currencySymbol} </label>
                      </div>
                    }                    
                    <div>
                      <label>chain network</label>
                      <label>{currencyDoc?.tokenType?.toUpperCase()}</label>
                    </div>
                    {
                       estimateBal > 0 && 
                       <div className="font_bold">
                          <label>Estimated arrival</label>
                          <label>{toFixed(estimateBal, 2)} {currencyDoc?.currencySymbol}</label>
                        </div>
                    }
                  </div>
                  <div className="text-center mt-4 mb-4">
                    <button 
                      className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Withdraw
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  	)
}

export default Withdraw;